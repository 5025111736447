import React, { ButtonHTMLAttributes, memo } from 'react';
import NextLink from 'next/link';
import { withLocationState } from 'src/common/migration/NavigationData';
import { StyleProps } from 'src/lib/styleProps';

export const GatsbyLink = memo(
  ({
    className,
    style,
    to,
    children,
    state,
    ...buttonProps
  }: ButtonHTMLAttributes<HTMLButtonElement> &
    StyleProps & {
      to: string;
      children: React.ReactNode;
      state?: any;
    }) => {
    const href = state ? withLocationState(to, state) : to;
    const normalizedHref = href.match(/^(\/|\w+:\/\/)/) ? href : '/' + href;
    return (
      <NextLink href={normalizedHref}>
        <a className={className} style={style} {...(buttonProps as any)}>
          {children}
        </a>
      </NextLink>
    );
  },
);
GatsbyLink.displayName = 'GatsbyLink';
