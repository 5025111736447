import { StyledComponentProps } from 'styled-components';
import { GatsbyLink } from 'src/common/migration/GatsbyLink';

// No idea how to type as, and no time to do so
/* eslint-disable */
export const coerceLinkProps = (
  to: string
): Pick<StyledComponentProps<any, any, any, any>, 'as'> & {
  href?: string;
  to?: string;
} =>
  to.toLowerCase().startsWith('http')
    ? {
        as: 'a',
        href: to,
      }
    : {
        as: GatsbyLink,
        to,
      };
