import queryString from 'query-string';

export const withLocationState = (url: string, data: Record<string, string | number>) => {
  if (!~url.indexOf('?')) {
    return url + '?' + queryString.stringify(data);
  } else {
    return (
      url +
      '?' +
      queryString.stringify({
        ...queryString.parse(url.substring(url.indexOf('?'))),
        ...data,
      })
    );
  }
};

export const getLocationState = (url: string): any => {
  if (!~url.indexOf('?')) {
    return undefined;
  } else {
    return queryString.parse(url.substring(url.indexOf('?')));
  }
};
