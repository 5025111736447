import { MediaQueryAllQueryable, useMediaQuery as _useMediaQuery } from 'react-responsive';
import { useClientReady } from 'src/lib/SSR/ClientOnly/ClientOnly';

export const useMediaQuery = (
  options: Partial<MediaQueryAllQueryable & { query?: string | undefined }>,
) => {
  const isClient = useClientReady();
  const mediaQueryResult = _useMediaQuery(options);
  return isClient && mediaQueryResult;
};
