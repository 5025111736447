import React from 'react';
import { IconBox, IconProps } from 'src/lib/IconBox';

const transforms = {
  up: 90,
  down: -90,
  left: 0,
  right: 180,
};

export interface ArrowIconProps extends IconProps {
  direction?: 'up' | 'down' | 'left' | 'right';
}

const ArrowIcon = ({ direction = 'left', ...props }: ArrowIconProps) => (
  <IconBox {...props}>
    <g transform={`rotate(${transforms[direction]} 32 32)`}>
      <path
        clipRule="evenodd"
        fillRule="evenodd"
        d="M64 32c0-2.2091-1.7909-4-4-4H4c-2.20914 0-4 1.7909-4 4 0 2.2091 1.79086 4 4 4h56c2.2091 0 4-1.7909 4-4z"
      />
      <path
        clipRule="evenodd"
        fillRule="evenodd"
        d="M34.8284 1.17157c-1.5621-1.5621-4.0947-1.5621-5.6568 0L1.17157 29.1716c-1.5621 1.5621-1.5621 4.0947 0 5.6568l28.00003 28c1.5621 1.5621 4.0947 1.5621 5.6568 0s1.5621-4.0947 0-5.6568L9.65685 32 34.8284 6.82843c1.5621-1.5621 1.5621-4.09476 0-5.65686z"
      />
    </g>
  </IconBox>
);

export default ArrowIcon;
