import styled, { css } from 'styled-components';
import { media } from 'src/config/breakpoints';
import { colors } from 'src/config/colors';

export const CleanUnorderedList = styled.ul`
  padding-left: 0;
  list-style-type: none;
  margin: 0;
`;

export const UnorderedList = styled(CleanUnorderedList)`
  li {
    padding-left: 0.75rem;
    position: relative;

    &::before {
      content: '•';
      left: 0;
      position: absolute;
      text-align: center;
      top: 0;
      width: 0.75rem;
    }
  }
`;

export const DashUnorderedList = styled(CleanUnorderedList)`
  li {
    padding-left: 1.875rem;
    position: relative;

    ${media.w.min.px768(css`
      padding-left: 2.75rem;
    `)};

    &::before {
      color: ${colors.pfp3bis};
      content: '–';
      left: 0;
      position: absolute;
      text-align: right;
      top: 0;
      width: 0.8125rem;

      ${media.w.min.px768(css`
        width: 1.125rem;
      `)};
    }
  }
`;
