export const HomepageRoute = '/';

const SourceAboutUsRoute = '/about-us';
const SourceCookiePolicyRoute = '/cookies-policy';
const SourceContactRoute = '/contact';
const SourceEmailChangeConfirmationRoute = '/email-change-confirmation';
const SourceForgotPasswordRoute = '/forgot-password';
const SourceForgotPasswordSuccessRoute = '/forgot-password-success';
const SourceLoginRoute = '/login';
const SourceLogoutRoute = '/logout';
const SourcePrivacyPolicyRoute = '/privacy-policy';
const SourceRegisterRoute = '/register';
const SourceRegisterConfirmationRoute = '/register-confirmation';
const SourceRegisterSuccessRoute = '/register-success';
const SourceResetPasswordRoute = '/reset-password';
const SourceTermsAndConditionsRoute = '/terms-and-conditions';

const TranslatedAboutUsRoute = '/o-nas';
const TranslatedCookiePolicyRoute = '/polityka-cookies';
const TranslatedContactRoute = '/kontakt';
const TranslatedEmailChangeConfirmationRoute = '/potwierdzenie-zmiany-adresu-email';
const TranslatedForgotPasswordRoute = '/nie-pamietam-hasla';
const TranslatedForgotPasswordSuccessRoute = '/resetowanie-hasla-udane';
const TranslatedLoginRoute = '/logowanie';
const TranslatedLogoutRoute = '/wyloguj';
const TranslatedPrivacyPolicyRoute = '/polityka-prywatnosci';
const TranslatedRegisterRoute = '/rejestracja';
const TranslatedRegisterConfirmationRoute = '/potwierdzenie-rejestracji';
const TranslatedRegisterSuccessRoute = '/rejestracja-udana';
const TranslatedResetPasswordRoute = '/zresetuj-haslo';
const TranslatedTermsAndConditionsRoute = '/regulamin';

export const AboutUsRoute = TranslatedAboutUsRoute;
export const CookiePolicyRoute = TranslatedCookiePolicyRoute;
export const ContactRoute = TranslatedContactRoute;
export const ForgotPasswordRoute = TranslatedForgotPasswordRoute;
export const ForgotPasswordSuccessRoute = TranslatedForgotPasswordSuccessRoute;
export const LoginRoute = TranslatedLoginRoute;
export const LogoutRoute = TranslatedLogoutRoute;
export const PrivacyPolicyRoute = TranslatedPrivacyPolicyRoute;
export const RegisterRoute = TranslatedRegisterRoute;
export const RegisterSuccessRoute = TranslatedRegisterSuccessRoute;
export const TermsAndConditionsRoute = TranslatedTermsAndConditionsRoute;

export const publicPathnameTranslations = {
  [SourceAboutUsRoute]: TranslatedAboutUsRoute,
  [SourceCookiePolicyRoute]: TranslatedCookiePolicyRoute,
  [SourceContactRoute]: TranslatedContactRoute,
  [SourceEmailChangeConfirmationRoute]: TranslatedEmailChangeConfirmationRoute,
  [SourceForgotPasswordRoute]: TranslatedForgotPasswordRoute,
  [SourceForgotPasswordSuccessRoute]: TranslatedForgotPasswordSuccessRoute,
  [SourceLoginRoute]: TranslatedLoginRoute,
  [SourceLogoutRoute]: TranslatedLogoutRoute,
  [SourcePrivacyPolicyRoute]: TranslatedPrivacyPolicyRoute,
  [SourceRegisterRoute]: TranslatedRegisterRoute,
  [SourceRegisterConfirmationRoute]: TranslatedRegisterConfirmationRoute,
  [SourceRegisterSuccessRoute]: TranslatedRegisterSuccessRoute,
  [SourceResetPasswordRoute]: TranslatedResetPasswordRoute,
  [SourceTermsAndConditionsRoute]: TranslatedTermsAndConditionsRoute,
};

export const publicTranslatedRoutes = [
  { source: SourceAboutUsRoute, translated: TranslatedAboutUsRoute },
  { source: SourceCookiePolicyRoute, translated: TranslatedCookiePolicyRoute },
  { source: SourceContactRoute, translated: TranslatedContactRoute },
  {
    source: SourceEmailChangeConfirmationRoute,
    translated: TranslatedEmailChangeConfirmationRoute,
  },
  { source: SourceForgotPasswordRoute, translated: TranslatedForgotPasswordRoute },
  { source: SourceForgotPasswordSuccessRoute, translated: TranslatedForgotPasswordSuccessRoute },
  { source: SourceLoginRoute, translated: TranslatedLoginRoute },
  { source: SourceLogoutRoute, translated: TranslatedLogoutRoute },
  { source: SourcePrivacyPolicyRoute, translated: TranslatedPrivacyPolicyRoute },
  { source: SourceRegisterRoute, translated: TranslatedRegisterRoute },
  { source: SourceRegisterConfirmationRoute, translated: TranslatedRegisterConfirmationRoute },
  { source: SourceRegisterSuccessRoute, translated: TranslatedRegisterSuccessRoute },
  { source: SourceResetPasswordRoute, translated: TranslatedResetPasswordRoute },
  { source: SourceTermsAndConditionsRoute, translated: TranslatedTermsAndConditionsRoute },
];

// public urls
export const ourProductsURL = 'https://www.pfp.com.pl/';
export const loansURL = 'https://www.pfp.com.pl/pozyczki';
export const subsidesURL = 'https://www.pfp.com.pl/dotacje';
export const facebookURL = 'https://www.facebook.com/pozyczkiPFP';
