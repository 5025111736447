import styled, { css } from 'styled-components';
import { colors } from 'src/config/colors';
import { LogoFooter } from 'src/common/images/LogoFooter';
import React, { memo, Reducer, useReducer } from 'react';
import { typography } from 'src/config/typography';
import { CleanUnorderedList } from 'src/common/components/List';
import { Button, CleanButton } from 'src/common/components/Button';
import FacebookIcon from 'src/assets/icons/FacebookIcon';
import { useMediaQuery } from 'src/common/migration/useMediaQuery';
import { below, media, widthBreakpoints } from 'src/config/breakpoints';
import { SimpleLink } from 'src/common/components/SimpleLink';
import { rem } from 'polished';
import {
  footerActiveMenuReducer,
  FooterActiveMenuState,
} from 'src/common/components/Footer/reducer';
import ArrowIcon from 'src/assets/icons/ArrowIcon';
import { StyleProps } from 'src/lib/styleProps';
import {
  AboutUsRoute,
  ContactRoute,
  CookiePolicyRoute,
  facebookURL,
  loansURL,
  PrivacyPolicyRoute,
  subsidesURL,
  TermsAndConditionsRoute,
} from 'src/public/publicRoutes';
import { coerceLinkProps } from 'src/common/utils/coerceLinkProps';
import { SpeakersListPageRoute } from 'src/speakers/routes';

const Content = styled.div`
  display: grid;
  grid-template-areas:
    'logo social'
    'menu menu'
    'copy copy';
  grid-template-columns: 1fr auto;
  grid-template-rows: auto;
  margin: 0 auto;
  max-width: 45.625rem;
  row-gap: 1.125rem;

  ${media.w.min.px576(css`
    row-gap: 2.625rem;
  `)};
`;

export const Container = styled.div<{ padding: number }>`
  background-color: ${colors.white};
  box-shadow: inset 0 0.625rem 1.875rem rgba(52, 63, 78, 0.2);
  margin: 0 -6.25rem;
  padding: 4.75rem 7.625rem ${({ padding }) => rem(36 + padding)} 7rem;

  ${({ padding }) =>
    media.w.min.px576(css`
      padding-bottom: ${rem(28 + padding)};
      padding-left: 7.5rem;
      padding-right: 7.5rem;
    `)}

  ${media.w.min.px992(css`
    padding-bottom: 1.75rem;
  `)};
`;

const Copy = styled.div`
  color: ${colors.blue05};
  grid-area: copy;

  ${media.w.min.px576(css`
    padding-top: 0.5rem;
  `)};
`;

const LinkGroup = styled.div`
  flex: 0 0 0%;

  &:not(:last-child) {
    margin-bottom: 1.75rem;
  }

  ${media.w.min.px576(css`
    margin-bottom: 0;
  `)};
`;

const Links = styled(CleanUnorderedList)<{ show: boolean }>`
  margin: 0;

  ${({ show }) =>
    media.w.max.px576(css`
      display: ${show ? 'block' : 'none'};
      padding: 0.9375rem 0 0.4375rem;
    `)}
`;

const Menu = styled.div`
  grid-area: menu;
  padding-top: 0.625rem;

  ${media.w.min.px576(css`
    display: flex;
    justify-content: space-between;
    padding-top: 0;
  `)};
`;

const Social = styled.div`
  align-self: end;
  color: ${colors.blue05};
  grid-area: social;
  justify-self: end;
  padding-bottom: 0.125rem;

  ${media.w.min.px576(css`
    padding-right: 0.3125rem;
  `)};
`;

const SocialLink = styled(SimpleLink)`
  ${media.w.min.px576(css`
    margin-left: 0.4375rem;
  `)};
`;

const SocialText = styled.span`
  ${media.w.max.px576(css`
    display: none;
  `)};
`;

const StyledLink = styled(Button)`
  color: ${colors.blue05};
  margin: 0.625rem 0;
  padding: 0.3125rem 0;
  white-space: nowrap;

  ${media.w.min.px576(css`
    margin: 0 0 0.25rem;
    padding: 0.0625rem 0 0;
  `)};
`;

const StyledLogoFooter = styled(LogoFooter)`
  grid-area: logo;
`;

const Title = styled(CleanButton)`
  align-items: center;
  color: ${colors.blue05};
  display: flex;
  font-family: ${typography.fontFamilyHeaders};
  font-size: 1rem;
  justify-content: space-between;
  line-height: 1.375rem;
  padding-right: 0.5rem;
  text-align: left;
  width: 100%;

  ${media.w.min.px576(css`
    display: inline-block;
    margin-bottom: 1.1875rem;
    padding-right: 0;
    pointer-events: none;
  `)};

  svg {
    ${media.w.min.px576(css`
      display: none;
    `)};
  }
`;

const Wrapper = styled.div`
  overflow: hidden;
  width: 100%;
`;

interface FooterMenuItem {
  label: string;
  url: string;
}

interface FooterMenuGroup {
  id: string;
  items: FooterMenuItem[];
  title: string;
}

const footerMenus: FooterMenuGroup[] = [
  {
    id: 'about-us',
    title: 'Poznaj nas',
    items: [
      { label: 'O fundacji', url: AboutUsRoute },
      { label: 'Nasi eksperci', url: SpeakersListPageRoute },
      { label: 'Kontakt', url: ContactRoute },
    ],
  },
  {
    id: 'our-services',
    title: 'Nasze usługi',
    items: [
      { label: 'Pożyczki', url: loansURL },
      { label: 'Dotacje', url: subsidesURL },
    ],
  },
  {
    id: 'help',
    title: 'Pomoc',
    items: [
      { label: 'Regulamin', url: TermsAndConditionsRoute },
      { label: 'Polityka cookies', url: CookiePolicyRoute },
      { label: 'Polityka prywatności', url: PrivacyPolicyRoute },
    ],
  },
];

export interface FooterProps {
  widgetPadding?: number;
}

export const Footer = memo<FooterProps & StyleProps>(({ widgetPadding = 0, className }) => {
  const isMobile = useMediaQuery({ maxWidth: below(widthBreakpoints.px576) });
  const [activeMenu, toggleActiveMenu] = useReducer<Reducer<FooterActiveMenuState, string>>(
    footerActiveMenuReducer,
    {},
  );

  return (
    <Wrapper className={className}>
      <Container padding={widgetPadding}>
        <Content>
          <StyledLogoFooter />

          <Social>
            <SocialText>Odwiedź nas na</SocialText>

            <SocialLink href={facebookURL}>
              <FacebookIcon size={isMobile ? 24 : 14} />
            </SocialLink>
          </Social>

          <Menu>
            {footerMenus.map((group, index) => (
              <LinkGroup key={index}>
                <Title onClick={() => toggleActiveMenu(group.id)}>
                  {group.title}

                  <ArrowIcon direction={activeMenu[group.id] ? 'up' : 'down'} size={7} />
                </Title>

                <Links show={activeMenu[group.id]}>
                  {group.items.map((item, index) => (
                    <li key={index}>
                      <StyledLink kind="link" {...coerceLinkProps(item.url)}>
                        {item.label}
                      </StyledLink>
                    </li>
                  ))}
                </Links>
              </LinkGroup>
            ))}
          </Menu>

          <Copy>
            &copy; Polska Fundacja Przedsiębiorczości
            <br />
            Copyright {new Date().getFullYear()} PFP
          </Copy>
        </Content>
      </Container>
    </Wrapper>
  );
});
Footer.displayName = 'Footer';
