import { buildPathname, buildRoute, setParam, translateSegments } from 'src/lib/router.utils';
import { concat } from 'ramda';

export const speakersItemParam = ':speakerId';

const sourceListSegment = 'speakers';

const segmentsTranslations = {
  [sourceListSegment]: 'trenerzy',
};

const segmentTranslator = (segments: string[]) => translateSegments(segments, segmentsTranslations);

const sourceListRouteSegments = [sourceListSegment];
const sourceItemRouteSegments = concat(sourceListRouteSegments, [speakersItemParam]);

const translatedListRouteSegments = segmentTranslator(sourceListRouteSegments);
const translatedItemRouteSegments = segmentTranslator(sourceItemRouteSegments);

const SourceSpeakersListRoute = buildRoute(sourceListRouteSegments);
const SourceSpeakersItemRoute = buildRoute(sourceItemRouteSegments);

const TranslatedSpeakersListRoute = buildRoute(translatedListRouteSegments);
const TranslatedSpeakersItemRoute = buildRoute(translatedItemRouteSegments);

export const speakersPathnameTranslations = {
  [buildPathname(sourceListRouteSegments)]: buildPathname(translatedListRouteSegments),
  [buildPathname(sourceItemRouteSegments)]: buildPathname(translatedItemRouteSegments),
};

export const speakersTranslatedRoutes = [
  { source: SourceSpeakersListRoute, translated: TranslatedSpeakersListRoute },
  { source: SourceSpeakersItemRoute, translated: TranslatedSpeakersItemRoute },
];

export const SpeakersListPageRoute = TranslatedSpeakersListRoute;
export const getSpeakersItemPageRoute = setParam(speakersItemParam)(TranslatedSpeakersItemRoute);
