import React from 'react';
import { IconBox, IconProps } from 'src/lib/IconBox';

const FacebookIcon = (props: IconProps) => (
  <IconBox {...props}>
    <path
      clipRule="evenodd"
      fillRule="evenodd"
      d="M37.2786 64V31.9963h9.4668L48 20.9676H37.2786l.0161-5.52c0-2.8764.2929-4.4177 4.72-4.4177h5.9183V0h-9.4682C27.092 0 23.0891 5.35009 23.0891 14.3473v6.6215H16v11.0287h7.0891V64z"
    />
  </IconBox>
);

export default FacebookIcon;
